.countdown {
  text-align: center;
  font-weight: bold;
  margin-left: -10px;
}

.countdown ul {
  padding: 0;
  margin: 0 auto;
  width: 260px;
}

.countdown li {
  background-color: #cacaca36;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  list-style-type: none;
  
  padding: 7px;
  margin: 2px;
  width: 60px;
  text-transform: uppercase;
}

.countdown li span {
  display: block;
  font-size: 20px;
}
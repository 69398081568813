

.housing {
  margin: 20px auto;
  position: relative;
  width: 280px;
  /*height: 756px;*/
  border-radius: 2px;
  border: 8px solid #151515;
  background-color: #151515;
  box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2);
}

.door {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-style: solid;
  border-color: #191919;
  border-width: 3px 12px;
  background-color: rgba(230, 230, 230, 0.1);
  z-index: 50;
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
  -webkit-transition: all 1.2s ease-in-out;
  -moz-transition: all 1.2s ease-in-out;
  transition: all 1.2s ease-in-out;
}
.door.open {
  -webkit-transform: rotateY(135deg) skew(0, -10deg);
  -moz-transform: rotateY(135deg) skew(0, -10deg);
  -ms-transform: rotateY(135deg) skew(0, -10deg);
  -o-transform: rotateY(135deg) skew(0, -10deg);
  transform: rotateY(135deg) skew(0, -10deg);
}
.door .handle {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  width: 6px;
  height: 63px;
  border-radius: 2px;
  background-color: #888;
  box-shadow: 0 0 4px 1px rgba(40, 40, 40, 0.25);
  cursor: pointer;
}
.door .handle:hover {
  background-color: #bbb;
}
.door .handle:active {
  -webkit-transform: translate(0, 4px);
  -moz-transform: translate(0, 4px);
  -ms-transform: translate(0, 4px);
  -o-transform: translate(0, 4px);
  transform: translate(0, 4px);
}

.foot {
  position: absolute;
  bottom: -14px;
  width: 10%;
  height: 6px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #888;
}
.foot:nth-of-type(1) {
  left: 0;
}
.foot:nth-of-type(2) {
  right: 0;
}

.rack {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
  list-style: none;
}
.rack {
  text-decoration: none;
  color: #16a8c7;
}
.rack a:hover {
  text-decoration: underline;
}

.rack li {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #282828;
  background-image: -webkit-linear-gradient(top, #282828, #222, #282828);
  background-image: linear-gradient(to bottom,#282828, #222, #282828);
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  text-align: center;
}
.rack li:first-of-type {
  border-top: none;
}
.rack li:last-of-type {
  border-bottom: none;
}
.rack li:active {
  opacity: 0.5;
}
.rack li:active .light {
  background-color: transparent !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  animation: none !important;
}
.rack li .light {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 1px solid #444;
}
.rack li .light:nth-of-type(1) {
  left: 18px;
  background-color: #16a8c7;
}
.rack li .light:nth-of-type(2) {
  left: 26px;
  -webkit-animation: 2s blink infinite;
  -moz-animation: 2s blink infinite;
  animation: 2s blink infinite;
}


.rowU{
  font-size: 9px;
  text-transform: uppercase;
}

.rowE{
  font-size: 9px;
  background-color: #dadada !important;
  background-image: none !important;
  color: #aaaaaa;
}



@-webkit-keyframes blink {
  0%, 70%, 90% {
    background-color: #66bb6a;
  }
  80%, 100% {
    background-color: #ffa726;
  }
}
@-moz-keyframes blink {
  0%, 70%, 90% {
    background-color: #66bb6a;
  }
  80%, 100% {
    background-color: #ffa726;
  }
}
@keyframes blink {
  0%, 70%, 90% {
    background-color: #66bb6a;
  }
  80%, 100% {
    background-color: #ffa726;
  }
}
.rack li:nth-of-type(1) .light:nth-of-type(2) {
  -webkit-animation-delay: 975ms;
  -moz-animation-delay: 975ms;
  animation-delay: 975ms;
}

.rack li:nth-of-type(2) .light:nth-of-type(2) {
  -webkit-animation-delay: 729ms;
  -moz-animation-delay: 729ms;
  animation-delay: 729ms;
}

.rack li:nth-of-type(3) .light:nth-of-type(2) {
  -webkit-animation-delay: 587ms;
  -moz-animation-delay: 587ms;
  animation-delay: 587ms;
}

.rack li:nth-of-type(4) .light:nth-of-type(2) {
  -webkit-animation-delay: 686ms;
  -moz-animation-delay: 686ms;
  animation-delay: 686ms;
}

.rack li:nth-of-type(5) .light:nth-of-type(2) {
  -webkit-animation-delay: 935ms;
  -moz-animation-delay: 935ms;
  animation-delay: 935ms;
}

.rack li:nth-of-type(6) .light:nth-of-type(2) {
  -webkit-animation-delay: 869ms;
  -moz-animation-delay: 869ms;
  animation-delay: 869ms;
}

.rack li:nth-of-type(7) .light:nth-of-type(2) {
  -webkit-animation-delay: 78ms;
  -moz-animation-delay: 78ms;
  animation-delay: 78ms;
}

.rack li:nth-of-type(8) .light:nth-of-type(2) {
  -webkit-animation-delay: 227ms;
  -moz-animation-delay: 227ms;
  animation-delay: 227ms;
}

.rack li:nth-of-type(9) .light:nth-of-type(2) {
  -webkit-animation-delay: 317ms;
  -moz-animation-delay: 317ms;
  animation-delay: 317ms;
}

.rack li:nth-of-type(10) .light:nth-of-type(2) {
  -webkit-animation-delay: 123ms;
  -moz-animation-delay: 123ms;
  animation-delay: 123ms;
}

.rack li:nth-of-type(11) .light:nth-of-type(2) {
  -webkit-animation-delay: 629ms;
  -moz-animation-delay: 629ms;
  animation-delay: 629ms;
}

.rack li:nth-of-type(12) .light:nth-of-type(2) {
  -webkit-animation-delay: 46ms;
  -moz-animation-delay: 46ms;
  animation-delay: 46ms;
}

.rack li:nth-of-type(13) .light:nth-of-type(2) {
  -webkit-animation-delay: 213ms;
  -moz-animation-delay: 213ms;
  animation-delay: 213ms;
}

.rack li:nth-of-type(14) .light:nth-of-type(2) {
  -webkit-animation-delay: 327ms;
  -moz-animation-delay: 327ms;
  animation-delay: 327ms;
}

.rack li:nth-of-type(15) .light:nth-of-type(2) {
  -webkit-animation-delay: 591ms;
  -moz-animation-delay: 591ms;
  animation-delay: 591ms;
}

.rack li:nth-of-type(16) .light:nth-of-type(2) {
  -webkit-animation-delay: 454ms;
  -moz-animation-delay: 454ms;
  animation-delay: 454ms;
}

.rack li:nth-of-type(17) .light:nth-of-type(2) {
  -webkit-animation-delay: 589ms;
  -moz-animation-delay: 589ms;
  animation-delay: 589ms;
}

.rack li:nth-of-type(18) .light:nth-of-type(2) {
  -webkit-animation-delay: 756ms;
  -moz-animation-delay: 756ms;
  animation-delay: 756ms;
}

.rack li:nth-of-type(19) .light:nth-of-type(2) {
  -webkit-animation-delay: 17ms;
  -moz-animation-delay: 17ms;
  animation-delay: 17ms;
}

.rack li:nth-of-type(20) .light:nth-of-type(2) {
  -webkit-animation-delay: 188ms;
  -moz-animation-delay: 188ms;
  animation-delay: 188ms;
}

.rack li:nth-of-type(21) .light:nth-of-type(2) {
  -webkit-animation-delay: 317ms;
  -moz-animation-delay: 317ms;
  animation-delay: 317ms;
}

.rack li:nth-of-type(22) .light:nth-of-type(2) {
  -webkit-animation-delay: 294ms;
  -moz-animation-delay: 294ms;
  animation-delay: 294ms;
}

.rack li:nth-of-type(23) .light:nth-of-type(2) {
  -webkit-animation-delay: 743ms;
  -moz-animation-delay: 743ms;
  animation-delay: 743ms;
}

.rack li:nth-of-type(24) .light:nth-of-type(2) {
  -webkit-animation-delay: 914ms;
  -moz-animation-delay: 914ms;
  animation-delay: 914ms;
}

.rack li:nth-of-type(25) .light:nth-of-type(2) {
  -webkit-animation-delay: 470ms;
  -moz-animation-delay: 470ms;
  animation-delay: 470ms;
}

.rack li:nth-of-type(26) .light:nth-of-type(2) {
  -webkit-animation-delay: 687ms;
  -moz-animation-delay: 687ms;
  animation-delay: 687ms;
}

.rack li:nth-of-type(27) .light:nth-of-type(2) {
  -webkit-animation-delay: 608ms;
  -moz-animation-delay: 608ms;
  animation-delay: 608ms;
}

.rack li:nth-of-type(28) .light:nth-of-type(2) {
  -webkit-animation-delay: 568ms;
  -moz-animation-delay: 568ms;
  animation-delay: 568ms;
}

.rack li:nth-of-type(29) .light:nth-of-type(2) {
  -webkit-animation-delay: 440ms;
  -moz-animation-delay: 440ms;
  animation-delay: 440ms;
}

.rack li:nth-of-type(30) .light:nth-of-type(2) {
  -webkit-animation-delay: 941ms;
  -moz-animation-delay: 941ms;
  animation-delay: 941ms;
}

.rack li:nth-of-type(31) .light:nth-of-type(2) {
  -webkit-animation-delay: 454ms;
  -moz-animation-delay: 454ms;
  animation-delay: 454ms;
}

.rack li:nth-of-type(32) .light:nth-of-type(2) {
  -webkit-animation-delay: 656ms;
  -moz-animation-delay: 656ms;
  animation-delay: 656ms;
}

.rack li:nth-of-type(33) .light:nth-of-type(2) {
  -webkit-animation-delay: 594ms;
  -moz-animation-delay: 594ms;
  animation-delay: 594ms;
}

.rack li:nth-of-type(34) .light:nth-of-type(2) {
  -webkit-animation-delay: 960ms;
  -moz-animation-delay: 960ms;
  animation-delay: 960ms;
}

.rack li:nth-of-type(35) .light:nth-of-type(2) {
  -webkit-animation-delay: 916ms;
  -moz-animation-delay: 916ms;
  animation-delay: 916ms;
}

.rack li:nth-of-type(36) .light:nth-of-type(2) {
  -webkit-animation-delay: 406ms;
  -moz-animation-delay: 406ms;
  animation-delay: 406ms;
}

.rack li:nth-of-type(37) .light:nth-of-type(2) {
  -webkit-animation-delay: 618ms;
  -moz-animation-delay: 618ms;
  animation-delay: 618ms;
}

.rack li:nth-of-type(38) .light:nth-of-type(2) {
  -webkit-animation-delay: 709ms;
  -moz-animation-delay: 709ms;
  animation-delay: 709ms;
}

.rack li:nth-of-type(39) .light:nth-of-type(2) {
  -webkit-animation-delay: 295ms;
  -moz-animation-delay: 295ms;
  animation-delay: 295ms;
}

.rack li:nth-of-type(40) .light:nth-of-type(2) {
  -webkit-animation-delay: 747ms;
  -moz-animation-delay: 747ms;
  animation-delay: 747ms;
}

.rack li:nth-of-type(41) .light:nth-of-type(2) {
  -webkit-animation-delay: 746ms;
  -moz-animation-delay: 746ms;
  animation-delay: 746ms;
}

.rack li:nth-of-type(42) .light:nth-of-type(2) {
  -webkit-animation-delay: 951ms;
  -moz-animation-delay: 951ms;
  animation-delay: 951ms;
}
body,
.fullscreen-enabled,
.bg.content {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='1820' preserveAspectRatio='none' viewBox='0 0 2500 1820'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1048%26quot%3b)' fill='none'%3e%3crect width='2500' height='1820' x='0' y='0' fill='rgba(240%2c 240%2c 240%2c 1)'%3e%3c/rect%3e%3cpath d='M53 1820L1873 0L2389 0L569 1820z' fill='url(%23SvgjsLinearGradient1049)'%3e%3c/path%3e%3cpath d='M2366 1820L546 0L-735 0L1085 1820z' fill='url(%23SvgjsLinearGradient1050)'%3e%3c/path%3e%3cpath d='M738.8568301244657 1820L2500 58.85683012446566L2500 1820z' fill='url(%23SvgjsLinearGradient1049)'%3e%3c/path%3e%3cpath d='M0 1820L1761.1431698755343 1820L 0 58.85683012446566z' fill='url(%23SvgjsLinearGradient1050)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1048'%3e%3crect width='2500' height='1820' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1049'%3e%3cstop stop-color='rgba(215%2c 215%2c 215%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(215%2c 215%2c 215%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1050'%3e%3cstop stop-color='rgba(215%2c 215%2c 215%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(215%2c 215%2c 215%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") !important;
  background-size: cover !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.clearmp {
    margin: 0 !important;
    padding: 0 !important;
}

.row-error-blink {
    background-color: #ffeaea;
    animation: blinkingText 2s infinite;
    border-bottom: #d3a6a6 !important;
    border-style: solid !important;
    border-width: 1px !important;
}

@keyframes blinkingText {
    0% {}

    40% {
        background-color: #ffeaea;
    }

    50% {
        background-color: #ffeaea;
    }

    60% {
        background-color: #ffeaea;
    }

    100% {}
}

.row-external {
    background-color: #051e34 !important;
    color: #fff !important;
}

.row-external2 {
  background-color: #33373a54 !important;
}

.cell-red {

    background-color: #ffbbbb !important;
    border-bottom: #b97373 !important;
    border-style: solid !important;
    border-width: 1px !important;
}


.cell-green {
    background-color: #dcffde !important;
    border-bottom: #84b973 !important;
    border-style: solid !important;
    border-width: 1px !important;
}



.pulsingErr {
    box-shadow: 0 0 0 0 #eb3939 ;
    padding: 10px;
    margin-top: 10px;

    -webkit-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
    -moz-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
    -ms-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
    animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
    color: #ffffff;
    transition: all 2s ease-in-out;
  }

  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    }
  }
  
  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    }
  }
  
  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    }
  }
  
  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
    }
  }
.react-calendar-timeline .rct-header-root {
  background: #7c97b4 !important;
  border-bottom: 1px solid #bbb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-calendar-timeline .rct-calendar-header {
  border: 1px solid #bbb;
  border-top-right-radius: 5px;
}

.rct-item {
  border-radius: 3px !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap; 
}

.rct-dateHeader {
  font-size: 11px !important;
}

.react-calendar-timeline .rct-items .item-type-suspended {
  background-color: rgb(226, 110, 110) !important;
  border: 1px solid rgb(211, 32, 32) !important;
}

.react-calendar-timeline .rct-items .item-type-past {
  background-color: rgb(212, 212, 212) !important;
  border: 1px solid rgb(148, 148, 148) !important;
}
.react-calendar-timeline .rct-items .item-type-current {
  background-color: #2196f3 !important;
  border: 1px solid #1b74bd !important;
}
.react-calendar-timeline .rct-items .item-type-holiday {
  background-color: #e9ad75 !important;
  border: 1px solid #f38721 !important;
}
.react-calendar-timeline .rct-items .item-type-future {
  background-color: rgb(164, 226, 156) !important;
  border: 1px solid rgb(131, 184, 124) !important;
  
}


.bgb{
  background: rgb(255,255,255) !important;
background: linear-gradient(180deg, rgb(255, 255, 255) 50%, rgba(184, 231, 253, 0.151) 100%) !important;
}

.bgb1{
  background: rgb(255,255,255) !important;
background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(210,210,210,1) 100%) !important;
}

.bgbox{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='300' height='100' preserveAspectRatio='none' viewBox='0 0 300 100'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1044%26quot%3b)' fill='none'%3e%3crect width='300' height='100' x='0' y='0' fill='rgba(240%2c 240%2c 240%2c 1)'%3e%3c/rect%3e%3cpath d='M10 100L110 0L122 0L22 100z' fill='url(%23SvgjsLinearGradient1045)'%3e%3c/path%3e%3cpath d='M71 100L171 0L202.5 0L102.5 100z' fill='url(%23SvgjsLinearGradient1045)'%3e%3c/path%3e%3cpath d='M142 100L242 0L335 0L235 100z' fill='url(%23SvgjsLinearGradient1045)'%3e%3c/path%3e%3cpath d='M297 100L197 0L171.5 0L271.5 100z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M226 100L126 0L111 0L211 100z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M154 100L54 0L-43.5 0L56.5 100z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M210.5939638307111 100L300 10.59396383071109L300 100z' fill='url(%23SvgjsLinearGradient1045)'%3e%3c/path%3e%3cpath d='M0 100L89.40603616928891 100L 0 10.59396383071109z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1044'%3e%3crect width='300' height='100' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1045'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1046'%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") !important;
  background-size: cover !important;
}

.sidebar,.bgb2 {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='300' height='500' preserveAspectRatio='none' viewBox='0 0 300 500'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1059%26quot%3b)' fill='none'%3e%3crect width='300' height='500' x='0' y='0' fill='rgba(5%2c 30%2c 52%2c 1)'%3e%3c/rect%3e%3cpath d='M13 500L513 0L615 0L115 500z' fill='url(%23SvgjsLinearGradient1060)'%3e%3c/path%3e%3cpath d='M106 500L606 0L664.5 0L164.5 500z' fill='url(%23SvgjsLinearGradient1060)'%3e%3c/path%3e%3cpath d='M287 500L-213 0L-298.5 0L201.5 500z' fill='url(%23SvgjsLinearGradient1061)'%3e%3c/path%3e%3cpath d='M189 500L-311 0L-407 0L93 500z' fill='url(%23SvgjsLinearGradient1061)'%3e%3c/path%3e%3cpath d='M101.67670813514107 500L300 301.6767081351411L300 500z' fill='url(%23SvgjsLinearGradient1060)'%3e%3c/path%3e%3cpath d='M0 500L198.32329186485893 500L 0 301.6767081351411z' fill='url(%23SvgjsLinearGradient1061)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1059'%3e%3crect width='300' height='500' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1060'%3e%3cstop stop-color='rgba(2%2c 20%2c 35%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(2%2c 20%2c 35%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1061'%3e%3cstop stop-color='rgba(2%2c 20%2c 35%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(2%2c 20%2c 35%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") !important;
  background-size: cover !important;
}

.sidebar .item{
  color: #ececec !important;
  margin: 0 !important;
  margin-left: 8px !important;
  padding: 8px !important;
  padding-left: 12px !important;
  
}

.sidebar .item:hover{
  color: #f7f7f7 !important;
  background-color: #0c2841 !important;

}

.tconapp.p-divider .p-divider-content {
  background-color: transparent !important ;
}

.tconapp.p-divider-horizontal:before{
  border-top: 1px #133c61 !important ;
  border-top-style: solid !important;
}

.tconapp .ui.label{
  background-color: #133c61 !important;
  color: #fff;
  font-style: normal !important;
  font-weight: 500;
  font-size: 12px;
  padding: 7px 25px 7px 25px;
}

.labelhidden{
  display: none !important;
}


.ui.horizontal.divider{
  color: #cdccc7 !important;
}

.ui.breadcrumb .section{
  font-weight: 400;
}

.labelappblink {
  box-shadow: 0 0 0 0 #eb3939 ;
  background-color: #c33c3c !important;
  padding: 6px !important;
  padding-right: 12px !important;
  padding-left: 10px !important;
  margin-top: -5px !important;
  cursor: pointer;
  text-align: center;
  -webkit-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
  -moz-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
  -ms-animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
  animation: pulsing 2s infinite cubic-bezier(0.30, 0, 0, 1);
  color: #ffffff;
  transition: all 2s ease-in-out;
}

.labelapp {
  background-color: #2185d0 !important;
}

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
}


.errorS {
  margin: auto;
  max-height: 500px;
  max-width: 700px;
  position: fixed;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgb(39, 39, 39);
}

.errorSView {
  margin: auto;
  max-height: 500px;
  max-width: 700px;
  position: absolute;
  text-align: center;
  top: 50px;
  left: 0;
  right: 0;
  color: rgb(39, 39, 39);
}

.boxname::before{
  content: attr(databeforecontent)
}


.p-scrollpanel-content{
  position: relative;
  padding: 0 !important;
}

.bi-link-path{
  stroke: #b8b8b8 !important;
  stroke-width: 2px !important;
  width: 20px !important;
}

.iconbutton{
  width: 30px !important;
  height: 30px !important;
  box-shadow: none !important;
  border: #ced4da 1px solid;
  border-radius: 3px;
  padding: 0px !important;
  margin: 0px !important;
  margin-left: 3px !important;
}

.ag-layout-normal{
  border-radius: 3px !important;
}

.appinfo{
  position: relative;
  bottom: 0;
  width: 100%;
  height: 35px;
  text-align: center;
  color: #cecccc;
  font-size: 10px;
  line-height: 11px;
  margin-top: 10px;

}

.wrapmenu{
  min-height: calc(100% - 185px);
}



.appbtnmtd{
  margin-top: 2px !important;
  margin-bottom: 5px !important;
}


.dc{
  color: #464646;
}

.bgcard{
  background-color: #f8f8f8 !important;
}

.labelabs{
  position: absolute !important;
  top:5px !important;
  right: 3px !important;
}


.statisticLabel span{
  font-size: 40px !important;
}

.statisticLabel i{
  font-size: 25px !important;
  top: -5px;
  position: relative;
}



.ag-cell-wrap-text{
  line-height: 15px !important;

}


.ag-input-field-input{
  min-height: 40px !important;
}


.ag-cell,.ag-row{
  min-height: 40px !important;
  --ag-internal-padded-row-height: 34px !important;
  --ag-internal-calculated-line-height: var(--ag-line-height, 34px) !important;

}
.ag-cell-wrapper{
  min-height: 33px !important;

}

.ag-row-odd:not(.row-error-blink):not(.row-external):not(.row-external2){
  background-color: #f3f3f3 !important;
}


.ag-row-selected::before{
  background-color: #d3e7fd !important;
  border-bottom: #61a5e6 1px solid;
  border-radius: 2px !important;
}


.iconRowAgGrid{
  font-size: 18px !important;
  position: absolute;
}


.p-dialog-content,.p-dialog-header{
  background-color:rgb(4, 176, 255) !important;
  color: #fff !important;
}

.bi-diagram-link-label{
  background-color: #e7e7e7 !important;
  border: #c5c5c5 solid 1px !important;
  color: #000 !important;
  height: 23px !important;
  padding: 0 !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  font-size: 10px !important;
}

input {
  min-width: 150px !important;
}


.calendarsticky {
  position: sticky;
  top: 0;
  z-index: 2000;
}

.rct-dateHeader{
  line-height: 12px;
  padding: 2px;
}


.actionFileCommisions{
  position: absolute;
  right:0px;
  bottom: 1px;
}

.actionFileCommisions2{
  position: absolute;
  right:20px;
  top:5px !important
}

.wrappedtab {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}


.commisionSMImage{
opacity: 0.3;
}

.editCommisionIcon{
  margin:0 !important;
  padding:0 !important;
  cursor: pointer;
  color: #ec9017;
}

.closeCommisionIcon{
  margin:0 !important;
  padding:0 !important;
  cursor: not-allowed;
  color: #919191;
}

.dateCardFile{
  font-size: 12px;
}

.wrappedTab{
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.imgPrimeCard{
  position: absolute !important;
  right: 5px;
  top: 5px;
  width: 100px;
  max-height: 90px;
  overflow: hidden;
}


.panel-wrapper {
  position: relative;
}

.btn {
  color: #fff;
  background: #2185d0;
  border-radius: 2px;
  padding: 1em;
  text-decoration: none;
  margin-bottom: 10px;
}
.show, .hide {
  position: absolute;
  bottom: -1em;
  z-index: 100;

}

.hide {display: none;}
.show:target {display: none;}
.show:target ~ .hide {display: block;}
.show:target ~ .panel {
   max-height: 2000px; 
}
.show:target ~ .fade {
  margin-top: 0;
}

.panel {
  position: relative;
  max-height: 150px;
  overflow: hidden;
  transition: max-height .5s ease;
}
.fade {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0.5382528011204482) 0%, rgba(255,255,255,1) 75%);
  height: 150px;
  margin-top: -100px;
  position: relative;
}

.show:target ~ .fade {
  height: 50px;
  margin-top: 0;
}


.imagelogo{
  width: 200px;
  margin: 0;
}


.wrappergrid{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrappergridcontent{
  max-width: 450px;
  width: 450px;
  padding: 20px;
}

.cytat{
  font-size: 14px;
  font-style: italic;
}
.cytatauthor{
  font-size: 10px;
  
}



  
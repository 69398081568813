.box {
  border-style: solid ;
  border-width: 1px;
  border-radius: 3px;
  width: 100%;
  height: 25px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(36, 36, 36);
}

.boxfree {
  border-color: rgb(165, 165, 165);
  background-color: rgb(240, 240, 240);
}

.boxlock {
  border-color: rgb(104, 104, 104);
  background-color: rgb(175, 175, 175);
}

.boxok {
  border-color: #79cf70;
  background-color: #92ffab;
}

.boxerr {
  border-color: #ce6565;
  background-color: #ffa6a6;
}


.otherports{
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  width: 100%;
  padding: 5px;
}


.gridbox{
  font-size: 10px !important;
}



